<template>
    <div class="container">
        <main class="main">
            <component 
                :is="headerComponent" 
                :config="config" 
                :user="user" />
            <router-view />
        </main>
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    computed: {
        ...mapState({
            user: state => state.user.user,
            config: state => state.config.config,
            showFooter: state => state.showFooter,
            showHeader: state => state.showHeader
        }),
        headerComponent() {
            if(this.showHeader)
                return () => import('./components/Header')
            else
                return null
        }
    }
}
</script>

<style lang="scss" scoped>
.main {
    padding-top: var(--headerHeight);
}
</style>
